import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Table, { ITableColumn } from '@/component/table/Table.tsx'
import Pagination from '@/component/pagination/Pagination.tsx'
import useQueryGetNotificationBio, { IAlertBio } from '@/api/notification/getNotificationBio.ts'
import { IAlertEmergency } from '@/api/notification/getNotificationSos.ts'
import { format } from 'date-fns'
import { equalString } from '@/util/strings.ts'
import AlertConfirmStatusBadge from '@/component/badge/AlertConfirmStatusBadge.tsx'
import BioAlertModal from '@/pages/notification/bio/BioAlertModal.tsx'
import SearchBar from '@/component/input/SearchBar.tsx'
import useDebounce from '@/util/hook/useDebounce.tsx'
import { useTranslation } from 'react-i18next'

export interface IProps {
    className?: string
}

const PAGE_SIZE = 10

const NotificationBioContainer: React.FC<IProps> = ({ className }) => {
    const { t } = useTranslation()
    const [currentPage, setCurrentPage] = useState(1)
    const [search, setSearch] = useState('')
    const [detailModalId, setDetailModalId] = useState<number>(-1)
    const detailModalRef = useRef<any>()
    const debouncedSearchText = useDebounce(search, 200)

    useEffect(() => {
        setCurrentPage(1)
    }, [debouncedSearchText])

    const { data, refetch } = useQueryGetNotificationBio({
        pageNum: currentPage,
        pageSize: PAGE_SIZE
    }, debouncedSearchText)

    useEffect(() => {
        refetch()
    }, [currentPage])

    const getGenderName = useCallback((value: string) => {
        switch (value) {
            case '남':
                return t('notification.bio.table.gender.male')
            case '여':
                return t('notification.bio.table.gender.female')
            default:
                return value
        }
    }, [t])

    const getResultName = useCallback((value: string) => {
        switch (value) {
            case '오작동':
                return t('notification.bio.table.process.malfunction')
            case '기타':
                return t('notification.bio.table.process.etc')
            case '119출동':
                return t('notification.bio.table.process.119')
            default:
                return value
        }
    }, [t])

    const columns = useMemo<ITableColumn<IAlertBio>[]>(() => {
        const result: ITableColumn<IAlertBio>[] = [
            {
                key: 'alert_time',
                header: () => <p
                    className={'font-medium text-[14px]'}>{t('notification.bio.table.emergency.column')}</p>,
                thClassName: 'bg-[#F0F5FF]',
                cell: (item: IAlertBio) => {
                    return <p className={'text-center'}>{format(item?.alert?.createdAt, 'yyyy/MM/dd HH:mm')}</p>
                }
            },
            {
                key: 'checked_time',
                header: () => <p
                    className={'font-medium text-[14px]'}>{t('notification.bio.table.confirmed.column')}</p>,
                thClassName: 'bg-[#F0F5FF]',
                cell: (item: IAlertBio) => {
                    return <p
                        className={'text-center'}>{item?.alert?.checkedAt && format(item?.alert?.checkedAt, 'yyyy/MM/dd HH:mm')}</p>
                }
            },
            {
                key: 'manager_name',
                header: () => <p className={'font-medium text-[14px]'}>{t('notification.bio.table.manager.column')}</p>,
                thClassName: 'bg-[#F0F5FF]',
                cell: (item: IAlertBio) => {
                    return <p
                        className={'text-center'}>{item?.alert?.managerName}</p>
                }
            },
            {
                key: 'protectee_name',
                header: () => <p
                    className={'font-medium text-[14px]'}>{t('notification.bio.table.userName.column')}</p>,
                thClassName: 'bg-[#F0F5FF]',
                cell: (item: IAlertBio) => {
                    return <p
                        className={'text-center'}>{item?.protectee?.name}</p>
                }
            },
            {
                key: 'protectee_gender_birthday',
                header: () => <p
                    className={'font-medium text-[14px]'}>{t('notification.bio.table.gender.column')} / {t('notification.bio.table.birthday.column')}</p>,
                thClassName: 'bg-[#F0F5FF]',
                cell: (item: IAlertBio) => {
                    return <p
                        className={'text-center'}>{getGenderName(item?.protectee?.gender)} / {item?.protectee?.birthDate && format(item?.protectee?.birthDate, 'yy-**-dd')}</p>
                }
            },
            {
                key: 'protectee_contact',
                header: () => <p className={'font-medium text-[14px]'}>{t('notification.bio.table.contact.column')}</p>,
                thClassName: 'bg-[#F0F5FF]',
                cell: (item: IAlertBio) => {
                    return <p
                        className={'text-center'}>{item?.protectee?.contact?.split('-')[0]}-****-****</p>
                }
            },
            {
                key: 'alert_location',
                header: () => <p className={'font-medium text-[14px]'}>{t('notification.bio.table.address.column')}</p>,
                thClassName: 'bg-[#F0F5FF]',
                cell: (item: IAlertBio) => {
                    return <p
                        className={'text-center'}>{item?.alert?.location?.address}</p>
                }
            },
            {
                key: 'state',
                header: () => <p className={'font-medium text-[14px]'}>{t('notification.bio.table.state.column')}</p>,
                thClassName: 'bg-[#F0F5FF]',
                cell: (item: IAlertBio) => {
                    const { hrate, hrateStatus, resp, respStatus } = item?.alert?.nameDetail || {}

                    const heartRateElement = hrate !== undefined ? (
                        <span className={equalString(hrateStatus, '정상') ? 'text-main_b' : 'text-sub_r'}>
                            {t('notification.bio.table.state.heartRate')}[{hrate}]
                        </span>
                    ) : null

                    const respElement = resp !== undefined ? (
                        <span className={resp >= 22 || !equalString(respStatus, '정상') ? 'text-sub_r' : 'text-main_b'}>
                            {t('notification.bio.table.state.respiration')}[{resp}]
                        </span>
                    ) : null

                    return (
                        <p className={'text-center'}>
                            {heartRateElement}
                            {heartRateElement && respElement ? ' / ' : ''}
                            {respElement}
                        </p>
                    )
                }
            },
            {
                key: 'status',
                header: () => <p className={'font-medium text-[14px]'}>{t('notification.bio.table.status.column')}</p>,
                thClassName: 'bg-[#F0F5FF]',
                cell: (item: IAlertBio) => {
                    return <div className={'flex justify-center items-center'}><AlertConfirmStatusBadge
                        value={item?.alert?.confirm} /></div>
                }
            },
            {
                key: 'alert_type',
                header: () => <p className={'font-medium text-[14px]'}>{t('notification.bio.table.process.column')}</p>,
                thClassName: 'bg-[#F0F5FF]',
                cell: (item: IAlertBio) => {
                    return <p
                        className={'text-center'}>{getResultName(item?.alert?.result)}</p>
                }
            }
        ]
        return result
    }, [])

    return <>
        <div className={`${className}`}>
            <div className={'flex py-[15px] px-[20px] items-center justify-end'}>
                <SearchBar inputParams={{ placeholder: t('notification.bio.search.placeholder') }}
                           className={'max-w-[230px]'} onChanged={text => {
                    setSearch(text)
                }} />
            </div>
            <Table
                data={data?.data || []} columns={columns} extractKey={(item) => item?.id}
                renderEmpty={<div className={'flex items-center justify-center h-full min-h-[200px]'}><p
                    className={'text-center'}>{t('notification.bio.table.empty')}</p></div>}
                className={'text-[14px]'}
                itemRowClassName={() => 'border-b border-solid border-gray2'}
                itemCommonClassName={() => 'py-[10px] px-[22px]'}
                onClickRow={(item: IAlertEmergency) => {
                    setDetailModalId(item?.id)
                    detailModalRef.current?.show()
                }}
            />
            {(data?.pagination?.totalPages || 0) > 0 && <Pagination
                className={'mt-[25px]'}
                current_page={data?.pagination?.currentPage}
                page_size={PAGE_SIZE}
                total_page={data?.pagination?.totalPages}
                fetchPage={(pageIndex) => {
                    setCurrentPage(pageIndex)
                }} />}
        </div>
        <BioAlertModal ref={detailModalRef} defaultId={detailModalId} refetchAlerts={refetch} />
    </>
}

export default NotificationBioContainer