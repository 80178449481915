import React, { createContext, useImperativeHandle, useRef, useState } from 'react'
import BaseModal from '@/component/modal/BaseModal.tsx'
import useQueryGetAlertBioLive from '@/api/notification/getAlertBioLive.ts'
import BioAlertDetail from '@/pages/notification/bio/BioAlertDetail.tsx'
import BioAlertItem from '@/pages/notification/bio/BioAlertItem.tsx'
import { isEmptyArray, isNotEmptyArray } from '@/util/array.ts'
import { useTranslation } from 'react-i18next'

export interface IProps {
    className?: string
    refetchAlerts?: () => void
    defaultId?: number
}

export type IAlertBioContextProps = {
    id?: number
    setId: (id: number) => void
    refetchList: () => void
    refetchAndResetSelection: () => void
}

export const BioAlertModalContext = createContext<IAlertBioContextProps>({
    id: undefined,
    setId: () => {
    },
    refetchList: () => {
    },
    refetchAndResetSelection: () => {
    }
})


const BioAlertModal = ({ defaultId, refetchAlerts }: IProps, ref: any) => {
    const { t } = useTranslation()

    const modalRef = useRef<any>()
    const [id, setId] = useState<number>()
    const { data: bioAlerts, refetch: refetchList } = useQueryGetAlertBioLive(true)

    useImperativeHandle(ref, () => ({
        show: () => {
            modalRef.current.show()
        },
        close: () => {
            modalRef.current.close()
        }
    }))

    const refetchAndResetSelection = async () => {
        const { data: resAlerts } = await refetchList()
        setId(undefined)

        const newAlerts = resAlerts?.bios
        if (isEmptyArray(newAlerts)) {
            modalRef.current.close()
            return
        }

        const myAlerts = newAlerts?.filter(item => item?.alert?.isMyTask == true)
        if (myAlerts && isNotEmptyArray(myAlerts)) {
            setId(myAlerts[0].id)
            return
        }

        const notMyAlerts = newAlerts?.filter(item => !item?.alert?.taskManagerAt)
        if (notMyAlerts && isNotEmptyArray(notMyAlerts)) {
            setId(notMyAlerts[0].id)
            return
        }
    }

    return (
        <BioAlertModalContext.Provider value={{ id, setId, refetchList, refetchAndResetSelection }}>
            <BaseModal
                ref={modalRef}
                width={'1200'}
                onOpenCallback={() => {
                    if (id) return
                    if (defaultId) {
                        setId(defaultId)
                    } else if ((bioAlerts?.bios?.length || 0) > 0) {
                        setId(bioAlerts?.bios[0]!.id)
                    }
                }}
                onCloseCallback={() => {
                    setId(undefined)
                    refetchAlerts && refetchAlerts()
                }}
                showCloseButton={false}>

                <div className={'p-[23px] min-h-[670px]'}>
                    <div className={'grid grid-cols-[324px_820px] gap-[10px]'}>
                        {/* Live 목록 */}
                        <div className={'flex flex-col p-[10px] justify-start items-start'}>
                            <div
                                className={'text-[20px] bg-sub_r text-white px-[15px] py-[5px] rounded-[5px]'}>
                                {t('notification.bioModal.title')}
                            </div>
                            <div className={'mt-[10px] flex flex-col gap-[10px] w-full h-full'}>
                                {Number(bioAlerts?.bios?.length) > 0 ? bioAlerts?.bios?.map(item => <BioAlertItem
                                        key={item?.id} {...item} />) :
                                    <div className={'grow flex items-center justify-center'}>
                                        <p>{t('notification.bioModal.empty')}</p>
                                    </div>}
                            </div>
                        </div>

                        {/* 알림 상세 내용 */}
                        <BioAlertDetail />
                    </div>
                </div>
            </BaseModal>
        </BioAlertModalContext.Provider>

    )
}

export default React.forwardRef(BioAlertModal)
